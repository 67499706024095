import "./TeamPage.css";
import { useState } from "react";
import team from "../../data/team";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

library.add(faLinkedin, faGithub);

export default function TeamPage() {
  const [showAllTeam, setShowAllTeam] = useState(true);
  const [showBoard, setShowBoard] = useState(false);
  const [showFrontEnd, setShowFrontEnd] = useState(false);
  const [showBackEnd, setShowBackEnd] = useState(false);
  const [showDesigners, setShowDesigners] = useState(false);
  const [showDataScience, setShowDataScience] = useState(false);

  let filteredTeamMembers = [];

  if (showAllTeam) {
    filteredTeamMembers = team;
  } else if (showBoard) {
    filteredTeamMembers = team.filter((member) =>
      member.category.includes("FOUNDER")
    );
  } else if (showFrontEnd) {
    filteredTeamMembers = team.filter((member) =>
      member.category.includes("FRONTEND DEVELOPER")
    );
  } else if (showBackEnd) {
    filteredTeamMembers = team.filter((member) =>
      member.category.includes("BACKEND DEVELOPER")
    );
  } else if (showDesigners) {
    filteredTeamMembers = team.filter((member) =>
      member.category.includes("UX/UI DESIGNER")
    );
  } else if (showDataScience) {
    filteredTeamMembers = team.filter((member) =>
      member.category.includes("DATA ANALYTIC")
    );
  }

  return (
    <>
      <div className="header">
        <h1>WE</h1>
        <h1>SUPPORT</h1>
        <h1>
          <span className="rainbow-text">DIVERSITY</span> IN TECH
        </h1>
      </div>
      <div className="team-filter">
        <button
          className={`btn ${showAllTeam ? "active" : ""}`}
          onClick={() => {
            setShowAllTeam(true);
            setShowBoard(false);
            setShowFrontEnd(false);
            setShowBackEnd(false);
            setShowDesigners(false);
            setShowDataScience(false);
          }}
        >
          ALL TEAM
        </button>

        <button
          className={`btn ${showFrontEnd ? "active" : ""}`}
          onClick={() => {
            setShowAllTeam(false);
            setShowBoard(false);
            setShowFrontEnd(true);
            setShowBackEnd(false);
            setShowDesigners(false);
            setShowDataScience(false);
          }}
        >
          FRONTEND DEVELOPERS
        </button>
        <button
          className={`btn ${showBackEnd ? "active" : ""}`}
          onClick={() => {
            setShowAllTeam(false);
            setShowBoard(false);
            setShowFrontEnd(false);
            setShowBackEnd(true);
            setShowDesigners(false);
            setShowDataScience(false);
          }}
        >
          BACKEND DEVELOPERS
        </button>
        <button
          className={`btn ${showDesigners ? "active" : ""}`}
          onClick={() => {
            setShowAllTeam(false);
            setShowBoard(false);
            setShowFrontEnd(false);
            setShowBackEnd(false);
            setShowDesigners(true);
            setShowDataScience(false);
          }}
        >
          DESIGNERS
        </button>
        <button
          className={`btn ${showDataScience ? "active" : ""}`}
          onClick={() => {
            setShowAllTeam(false);
            setShowBoard(false);
            setShowFrontEnd(false);
            setShowBackEnd(false);
            setShowDesigners(false);
            setShowDataScience(true);
          }}
        >
          DATA SCIENCE
        </button>
        <button
          className={`btn ${showBoard ? "active" : ""}`}
          onClick={() => {
            setShowAllTeam(false);
            setShowBoard(true);
            setShowFrontEnd(false);
            setShowBackEnd(false);
            setShowDesigners(false);
            setShowDataScience(false);
          }}
        >
          BOARD
        </button>
      </div>
      <div className="team-container">
        {filteredTeamMembers.map((member) => (
          <div className="member-card" key={member.id}>
            <div
              className="member-image"
              style={{ backgroundImage: `url(${member.img})` }}
            >
              <div className="member-overlay">
                <h3 className="member-name">{member.name}</h3>

                <p className="member-category">{member.category}</p>

                <p className="icons">
                  <a href={member.gitHub} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                  <a href={member.linkedIn} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
