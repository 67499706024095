import './App.css';
import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import 'animate.css';
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import TeamPage from '../TeamPage/TeamPage';
import HomePage from '../HomePage/HomePage';

function App() {
  const [mode, setMode] = useState("");

  const toggleMode = () => {
    if (mode === "") {
      setMode("light");
    } else {
      setMode("");
    }
  };

  useEffect(() => {
    document.body.className = mode;
  }, [mode]);

  return (
    <div className={`${mode} container`}>
      <NavBar toggleMode={toggleMode} mode={mode}/>
      <Routes>
        <Route path="/" element={<HomePage mode={ mode} />} />
        <Route path="/about" />
        <Route path="/projects" />
        <Route path="/team" element={ <TeamPage/>} />
        <Route path="/donate" />
        <Route path="/contact" />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
