import asti from "../assests/images/team/av.jpeg"
import img from "../assests/images/img-placeholder.jpeg"

const team = [
    {
        id: 1,
        name: "Asti",
        img: asti,
        category: ["FOUNDER"," / ", "FRONTEND DEVELOPER"],
        linkedIn: "https://www.linkedin.com/in/anastasiiaasti/",
        gitHub: "https://github.com/AnastasiiaAsti",
        portfolio: "https://www.asticodes.dev",
        email: "asti@asticodes.dev"
    },
    {
        id: 2,
        name: "Jane1",
        img: img,
        category: ["FRONTEND DEVELOPER"],
        linkedIn: "https://www.linkedin.com/in/anastasiiaasti/",
        gitHub: "https://github.com/AnastasiiaAsti",
        portfolio: "https://www.asticodes.dev",
        email: "asti@asticodes.dev"
    },
    {
        id: 3,
        name: "Jane2",
        img: img,
        category: ["BACKEND DEVELOPER"],
        linkedIn: "https://www.linkedin.com/in/anastasiiaasti/",
        gitHub: "https://github.com/AnastasiiaAsti",
        portfolio: "https://www.asticodes.dev",
        email: "asti@asticodes.dev"
    },
    {
        id: 4,
        name: "Jane3",
        img: img,
        category: ["UX/UI DESIGNER"],
        linkedIn: "https://www.linkedin.com/in/anastasiiaasti/",
        gitHub: "https://github.com/AnastasiiaAsti",
        portfolio: "https://www.asticodes.dev",
        email: "asti@asticodes.dev"
    },
    {
        id: 5,
        name: "Joe4",
        img: img,
        category: ["DATA ANALYTIC"],
        linkedIn: "https://www.linkedin.com/in/anastasiiaasti/",
        gitHub: "https://github.com/AnastasiiaAsti",
        portfolio: "https://www.asticodes.dev",
        email: "asti@asticodes.dev"
    }
]

export default team;