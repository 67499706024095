import { Link } from "react-router-dom";
import "./TeamStatement.css";
import heroImg from "../../assests/images/hero-3.avif";
import lightHeroImg from "../../assests/images/light-hero-3.avif";

export default function TeamStatement({ mode }) {
  return (
    <>
      <div className="team">
        <h1 className="team-header animate__animated animate__fadeInLeft">
          Want to be a part of our team?
        </h1>
        <p className="team-p animate__animated animate__fadeInLeft">
          By embracing a diverse workforce, we foster a collaborative
          environment where ideas flourish and innovation thrives. We believe
          that by bringing together individuals from different cultures,
          genders, races, abilities, and identities, we can approach
          environmental issues with fresh perspectives and develop holistic
          solutions that consider the needs of all communities.
        </p>
        <p className="team-p animate__animated animate__fadeInLeft">
          Join us and be part of a passionate international community dedicated
          to making a difference.
        </p>
        <Link
          to="/about"
          className="link-btn animate__animated animate__fadeInLeft"
        >
          CONTACT US
        </Link>
      </div>
      <img
        className="hero animate__animated animate__fadeInRight"
        src={mode === "" ? heroImg : lightHeroImg}
        alt=""
      />
    </>
  );
}
