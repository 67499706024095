import { Link } from "react-router-dom";
import "./MissionStatement.css";
import heroImg from "../../assests/images/hero-2.avif";
import lightHeroImg from "../../assests/images/light-hero-2.avif";

export default function MissionStatement({ mode }) {
  return (
    <>
      <div className="mission">
        <h1 className="mission-header animate__animated animate__fadeInLeft">
          Our Mission
        </h1>
        <p className="mission-p-1 animate__animated animate__fadeInLeft ">
          EchoHarmony Society is an international organization. Our mission is
          to leverage technology as a catalyst for positive change, focusing on
          environmental projects while embracing diversity and preserving the
          wonders of nature. As a non-profit organization, we are dedicated to
          creating innovative solutions that address pressing environmental
          challenges while fostering inclusivity and appreciation for the
          world's diverse cultures.
        </p>
        <p className="mission-p-1 animate__animated animate__fadeInLeft">
          Together, with our partners and supporters, we aspire to build a
          future where technology serves as a bridge between humanity and
          nature, where diversity is celebrated and valued, and where the
          delicate balance of our planet is preserved for present and future
          generations. By combining innovation, inclusivity, and environmental
          stewardship, we envision a world where everyone can thrive in harmony
          with the Earth.
        </p>
        <Link
          to="/about"
          className="link-btn animate__animated animate__fadeInLeft "
        >
          ABOUT US
        </Link>
      </div>
      <img
        className="hero animate__animated animate__fadeInRight"
        src={mode === "" ? heroImg : lightHeroImg}
        alt=""
      />
    </>
  );
}
