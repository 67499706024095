import MissionStatement from "../../components/MissionStatement/MissionStatement";
import TeamStatement from "../../components/TeamStatement/TeamStatement";
import DonateStatement from "../../components/DonateStatement/DonateStatement";

export default function HomePage({ mode }) {
  return (
    <>
      <MissionStatement mode={mode} />
      <TeamStatement mode={mode} />
      <DonateStatement mode={mode} />
    </>
  );
}
