import { Link } from "react-router-dom";
import "./DonateStatement.css";
import heroImg from "../../assests/images/hero-4.avif";
import lightHeroImg from "../../assests/images/light-hero-4.avif";

export default function DonateStatement({ mode }) {
  return (
    <>
      <div className="donate">
        <h1 className="donate-header animate__animated animate__fadeInLeft">
          Support the cause
        </h1>
        <p className="donate-p animate__animated animate__fadeInLeft">
          One of our goals is to create paid fellowships, internships,
          scholarship opportunities, and debt relief for those who are
          underrepresented, including but not limited to women, members of the
          LGBTQ+ community, and people of color. Helping members of the
          community who struggle economically will provide them with the liberty
          and flexibility necessary to explore new career paths in science and
          technology.
        </p>
        <p className="donate-p animate__animated animate__fadeInLeft ">
          To help us grow these opportunities, please consider making a
          tax-deductible donation to EcoHarmony Society today.
        </p>
        <Link
          to="/about"
          className="link-btn animate__animated animate__fadeInLeft"
        >
          DONATE
        </Link>
      </div>
      <img
        className="hero animate__animated animate__fadeInRight"
        src={mode === "" ? heroImg : lightHeroImg}
        alt=""
      />
    </>
  );
}
