import "./Footer.css";
import { Link } from "react-router-dom";
import SubscribeForm from "../SubscribeForm/SubscribeForm";

export default function Footer() {
  return (
    <>
      <div className="stripe"></div>
      <footer className="footer-container">
        <div className="upper-footer">
          <div className="subscribe">
            <SubscribeForm />
          </div>
          <div className="footer-links">
            <Link to="/">HOME</Link>

            <Link to="/about">ABOUT</Link>

            <Link to="/projects">PROJECTS</Link>

            <Link to="/team">OUR TEAM</Link>

            <Link to="/donate">DONATE</Link>

            <Link to="/explore">EXPLORE</Link>

            <Link to="/contact">CONTACT</Link>

            <Link to="/blog">BLOG</Link>
          </div>
        </div>
        <div className="lower-footer">
          <p>@2023 by AstiCodes.dev</p>
        </div>
      </footer>
    </>
  );
}
